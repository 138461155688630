import React, { useRef,useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import EventIcon from '@mui/icons-material/Event';
import PeopleIcon from '@mui/icons-material/People';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import MicExternalOnIcon from '@mui/icons-material/MicExternalOn';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import StarIcon from '@mui/icons-material/Star';
import Dateicon from '@mui/icons-material/AccessTime';
import HotelIcon from '@mui/icons-material/Hotel';
import FeedbackIcon from '@mui/icons-material/Feedback';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Products from './components/Products';
import Carts from './CartPage';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import Overview from './components/Home';
import Events from './components/Events';
import Exhibitors from './components/Exhibitors';
import OrderManagement from './OrderManagement';
import Sponsors from './components/Sponsors';
import Stage_check from './stage_check';
import SchoolManagement from './SchoolManagement';
import SchoolIcon from '@mui/icons-material/School';
import News from './components/News';
import EmailEditor from './components/EmailEditor';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import Rating from './components/Rating';
import Generate from './Generate';
import GenerateEdutech from './GenerateEdutech';
import Feedback from './Feedback';
import EnquireTrack from './components/EnquireTrack';
import EnquireTrackEdutech from './components/EnquireTrackEdutech';
import Hotel from './Hotel';
import PasswordInput from "./PasswordInput";
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import SettingsModal from './SettingsModal';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import DashboardIcon from '@mui/icons-material/Dashboard';
import NotificationMenu from './NotificationMenu';
import { Mail as MailIcon } from '@mui/icons-material';
import {Dialog, DialogActions,
  DialogContent, DialogContentText, DialogTitle,Slide
} from '@mui/material';
import DownloadAppAd from './components/DownloadAppAd';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import Offers from './components/Offers';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import GotTalentManagment from './components/GotTalentManagment';
import NotificationForm from './NotificationForm';
import ReportProblem from './ReportProblem';
import SendIcon from '@mui/icons-material/Send';
import { Collapse } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MiningView from './MiningView'
import AgritechView from './AgritechView'
import EdutechView from './EdutechView'
import EdutechViewForum from './EdutechViewForum'
import ForumIcon from '@mui/icons-material/Forum';
import ProblemView from './ProblemView'
import Stage_Check_Edutech from './Stage_Check_Edutech'
import VideoCallIcon from '@mui/icons-material/VideoCall';
import EDUTECHVideo from './EDUTECHVideo'
import SpeakersEdutechForum from './SpeakersEdutech'
import EdutechOfficialPartners from './EdutechOfficialPartners'
import TransferredParticipants from './TransferredParticipants'
import EdutechPartners from './EdutechSponsors'
import HandshakeIcon from '@mui/icons-material/Handshake';
import GroupsIcon from '@mui/icons-material/Groups';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import AgendaManagementEdutech from './components/AgendaManagementEdutech'
import PublicIcon from '@mui/icons-material/Public';
import EdutechPublicInfo from './EdutechPublicInfo'
import SwitchLeftIcon from '@mui/icons-material/SwitchLeft';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import StudentServices from './studentServices';
import ControlForms from './ControlForms';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const theme = createTheme({
  palette: {
    primary: {
      main: "#8B0000"
    },
    secondary: {
      main: "#000000"
    }
  }
});

const theme2 = createTheme({
  palette: {
    primary: {
      main: "#000000"
    }
  }
});

const drawerWidth = 240;

function ResponsiveDrawer(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [selectedComponent, setSelectedComponent] = useState(() => sessionStorage.getItem('selectedComponent') || '');
  const [username, setUsername] = useState(() => sessionStorage.getItem('username') || null);
  const [password, setPassword] = useState('');
  const [authenticated, setAuthenticated] = useState(() => sessionStorage.getItem('authenticated') === 'true');
  const [level1, setLevel1] =  useState(() => sessionStorage.getItem('level1') === 'true');
  const [level2, setLevel2] =  useState(() => sessionStorage.getItem('level2') === 'true');
  const [level3, setLevel3] =  useState(() => sessionStorage.getItem('level3') === 'true');
  const [level4, setLevel4] =  useState(() => sessionStorage.getItem('level4') === 'true');
  const [level5, setLevel5] =  useState(() => sessionStorage.getItem('level5') === 'true');
  const [level6, setLevel6] =  useState(() => sessionStorage.getItem('level6') === 'true');
  const [level7, setLevel7] =  useState(() => sessionStorage.getItem('level7') === 'true');

  const [notifications, setNotifications] = useState([]);
  const [expandedSection, setExpandedSection] = React.useState(null);
const handleExpandClick = (section) => {
  setExpandedSection(expandedSection === section ? null : section);
};
  useEffect(() => {
    // Retrieve notifications from sessionStorage
    const storedNotifications = sessionStorage.getItem('notifications');

    // Parse the stored data
    const parsedNotifications = storedNotifications
      ? JSON.parse(storedNotifications) // For complex data
      : []; // For simple data, use .split(',') instead

    setNotifications(parsedNotifications);
  }, []);
  const [isAnimating, setIsAnimating] = useState(false);
  const [name, setName] = useState(() => sessionStorage.getItem('name') || '');
  const [avatar, setAvatar] = useState(() => sessionStorage.getItem('avatar') || '');
  const sessionTimeoutRef = useRef(null);


  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const handleSettingsOpen = () => {
    setSettingsModalOpen(true);
  };
  
  const handleSettingsClose = () => {
    setSettingsModalOpen(false);
  };

  const [DialogMessage, setDialogMessage] = useState("");

  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  
  // Update user information
  const updateUser = (updatedUser) => {
    setName(updatedUser.name);
    setAvatar(updatedUser.avatar);
    sessionStorage.setItem('name', updatedUser.name);
    sessionStorage.setItem('avatar', updatedUser.avatar);


  };
  

  const [levelnum, setLevelnum] =  useState(() => sessionStorage.getItem('levelnum') || null);
  const [MAX_SELECTION_LIMIT, setMAX_SELECTION_LIMIT] =  useState(() => sessionStorage.getItem('MAX_SELECTION_LIMIT') || null);

  const [sessionTimeout, setSessionTimeout] = useState(null);
  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  

  useEffect(() => {
    if (authenticated) {
      sessionStorage.setItem('username', username);
      sessionStorage.setItem('authenticated', 'true');
      sessionStorage.setItem('level1', level1);
      sessionStorage.setItem('level2', level2);
      sessionStorage.setItem('level3', level3);
      sessionStorage.setItem('level4', level4);
      sessionStorage.setItem('level5', level5);
      sessionStorage.setItem('level6', level6);
      sessionStorage.setItem('level7', level7);
      sessionStorage.setItem('MAX_SELECTION_LIMIT', MAX_SELECTION_LIMIT);
      sessionStorage.setItem('levelnum', levelnum);
      sessionStorage.setItem('name', name);
      sessionStorage.setItem('avatar', avatar);
      sessionStorage.setItem('notifications', JSON.stringify(notifications));



      startSessionTimeout();
       // Add event listeners to track user activity
       document.addEventListener('click', resetSessionTimeout);
       document.addEventListener('keypress', resetSessionTimeout);
       document.addEventListener('mousemove', resetSessionTimeout);
    } else {
      clearSessionData();

    }
    return () => {
      // Clean up event listeners on unmount or logout
      document.removeEventListener('click', resetSessionTimeout);
      document.removeEventListener('keypress', resetSessionTimeout);
      document.removeEventListener('mousemove', resetSessionTimeout);
      clearTimeout(sessionTimeoutRef.current);
    };
  }, [authenticated, level1, level2, level3, level4, level5,level6,level7, levelnum,name,avatar,username,notifications]);
  

  const startSessionTimeout = () => {
    clearTimeout(sessionTimeoutRef.current);
    sessionTimeoutRef.current = setTimeout(logout, 10 * 60 * 1000); // 10 minutes in milliseconds
  };
  const resetSessionTimeout = () => {
    startSessionTimeout();
  };
  const clearSessionData = () => {
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('authenticated');
    sessionStorage.removeItem('level1');
    sessionStorage.removeItem('level2');
    sessionStorage.removeItem('level3');
    sessionStorage.removeItem('level4');
    sessionStorage.removeItem('level5');
    sessionStorage.removeItem('level6');
    sessionStorage.removeItem('level7');
    sessionStorage.removeItem('levelnum');
    sessionStorage.removeItem('selectedComponent');
    sessionStorage.removeItem('name');
    sessionStorage.removeItem('avatar');
    sessionStorage.removeItem('notifications');

    clearTimeout(sessionTimeoutRef.current);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }

  }
  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogout = () => {
    setAuthenticated(false);
    clearSessionData();
    handleOpenDialog();
    setIsAnimating(false)
    setDialogMessage("You have been logged out.");
  };

  const logout = () => {
    setIsAnimating(false)
    setAuthenticated(false);
    clearSessionData();
    handleOpenDialog();
    setDialogMessage("You have been logged out for not using the app.");
  };
  const handleListItemClick = (componentName) => {
    setSelectedComponent(componentName);
    sessionStorage.setItem('selectedComponent', componentName);
    handleDrawerClose();
    resetSessionTimeout(); // Reset session timeout on interaction
  };


  const handleLogin = () => {
    setIsAnimating(true);
    const lowercaseUsername = username.toLowerCase(); // Convert username to lowercase
  
    axios.post('https://edutech-backend-471f7cb69116.herokuapp.com/api/loginedu', { username: lowercaseUsername, password })
      .then(response => {
        if (response.data.authenticated) {
          setAuthenticated(true);
          setName(response.data.name);
         
          if (response.data.level === "2") {
            setLevel2(true);
          }
          if (response.data.level === "3") {
            setLevel3(true); // Set level3 to true for level 3 users

          }
          if (response.data.level === "4") {
            setLevel4(true); // Set level4 to true for level 3 users
          }
          if (response.data.level === "5") {
            setLevel5(true); // Set level4 to true for level 3 users
          }
          if (response.data.level === "6") {
            setLevel6(true); // Set level4 to true for level 3 users
          }
          if (response.data.level === "7") {
            setLevel7(true); // Set level4 to true for level 3 users
            setMAX_SELECTION_LIMIT(response.data.max_export)
          }
          else{
            setLevel1(true); 
            setMAX_SELECTION_LIMIT(response.data.max_export)
          }
          setLevelnum(response.data.level);
          sessionStorage.setItem('levelnum', response.data.level);

          setAvatar(response.data.image);

          setNotifications(response.data.notifications.filter(noti => noti.status === "not done"));

          //(getDefaultComponent(response.data.level)); // Set default component based on level
          startSessionTimeout();
        } else {
          handleOpenDialog();
          setDialogMessage("Incorrect username or password")
        }
      })
      .catch(error => {
        handleOpenDialog();
          setDialogMessage("An error occurred while logging in. Please try again later.")
      });
  };
  
  
  if (!authenticated) {
    return (
      <div
        style={{
          backgroundColor: "#f4f4f9",
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          fontFamily: "'Roboto', sans-serif",
          overflow: "hidden",
        }}
      >
        <Dialog
          open={openDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setOpenDialog(false)}
        >
          <DialogTitle>{"System"}</DialogTitle>
          <DialogContent>
            <DialogContentText>{DialogMessage}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDialog(false)}>Close</Button>
          </DialogActions>
        </Dialog>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#ffffff",
            padding: "30px",
            borderRadius: "12px",
            boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.15)",
            width: "100%",
            maxWidth: "360px",
            animation: "fadeIn 0.5s ease-in-out",
          }}
        >
          <img
            src="https://edugate-eg.com/wp-content/uploads/2023/04/10-YEARS-EDUGATE-copy.png"
            alt="Logo"
            style={{
              borderRadius: "8px",
              marginBottom: "15px",
              width: "80px",
              transform: isAnimating ? "scale(1.2) rotate(360deg)" : "scale(1)",
              transition: "transform 0.6s ease-in-out",
            }}
          />
          <h2
            style={{
              color: "#2c3e50",
              marginBottom: "20px",
              fontWeight: "500",
              fontSize: "22px",
            }}
          >
            EDU GATE Dashboard
          </h2>
          <ThemeProvider theme={theme2}>
            <TextField
              id="username"
              label="Username"
              margin="normal"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              fullWidth
              required
              InputLabelProps={{
                style: { color: "#34495e" },
              }}
              InputProps={{
                style: {
                  color: "#34495e",
                  borderRadius: "6px",
                },
              }}
              sx={{
                marginBottom: "15px",
              }}
            />
            <PasswordInput
              password={password}
              
              handlePassword={(e) => setPassword(e.target.value)}
              sx={{
                marginBottom: "20px", // Add spacing between password input and button
              }}
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              sx={{
                marginTop: "15px", // Ensure margin between button and input
                padding: "10px",
                fontWeight: "600",
                backgroundColor: "#3498db",
                borderRadius: "6px",
                "&:hover": {
                  backgroundColor: "#2980b9",
                },
              }}
              onClick={handleLogin}
            >
              Login
            </Button>
          </ThemeProvider>
        </Box>
      </div>
    );
  }
  
  const drawer = (
    <div>
      <Toolbar />
      <Box
        sx={{
          height: 120,
          display: 'block',
          margin: 'auto',
          marginBottom: 2,
        }}
        component="img"
        alt="Logo"
        src="https://edugate-eg.com/wp-content/uploads/2023/04/10-YEARS-EDUGATE-copy.png"
      />
      <Divider />
      <List>
        {/* EDUGATE Section */}
        <ListItem button onClick={() => handleExpandClick('EDUGATE')}
       
        >
        <Avatar alt="EDU GATE Logo" style={{ marginRight: 16 }} src="./Edutech_logo.ico" />
          <ListItemText primary="EDU GATE" />
          {expandedSection === 'EDUGATE' ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={expandedSection === 'EDUGATE'} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {!(level3 || level2 || level4 || level5 || level6 || level7) && (
              <ListItem button onClick={() => handleListItemClick('Overview')}
              sx={{
                backgroundColor: selectedComponent === 'Overview' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><TrendingUpIcon /></ListItemIcon>
                <ListItemText primary="Overview" />
              </ListItem>
            )}
            {!(level3 || level2 || level4 || level5 || level6 || level7) && (
              <ListItem button onClick={() => handleListItemClick('Events')}
              sx={{
                backgroundColor: selectedComponent === 'Events' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><EventIcon /></ListItemIcon>
                <ListItemText primary="Events" />
              </ListItem>
            )}
            {!(level3 || level2 || level4 || level5 || level7) && (
              <ListItem button onClick={() => handleListItemClick('Exhibitors')}
              sx={{
                backgroundColor: selectedComponent === 'Exhibitors' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><PeopleIcon /></ListItemIcon>
                <ListItemText primary="Exhibitors" />
              </ListItem>
            )}
            {!(  level2 || level4 || level5 || level6|| level3) && (
              <ListItem button onClick={() => handleListItemClick('Registrations')}
              sx={{
                backgroundColor: selectedComponent === 'Registrations' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><HowToRegIcon /></ListItemIcon>
                <ListItemText primary="Registrations" />
              </ListItem>
            )}
            {!(  level2 || level4 || level5 || level6) && (
              <ListItem button onClick={() => handleListItemClick('TransferredParticipants')}
              sx={{
                backgroundColor: selectedComponent === 'TransferredParticipants' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><SwitchLeftIcon /></ListItemIcon>
                <ListItemText primary="Transferred Participants" />
              </ListItem>
            )}
             {!(  level2 || level4 || level5 || level6) && (
              <ListItem button onClick={() => handleListItemClick('StudentServices')}
              sx={{
                backgroundColor: selectedComponent === 'StudentServices' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><AccountBalanceIcon /></ListItemIcon>
                <ListItemText primary="Partnerships" />
              </ListItem>
            )}


            {!(level3 || level2 || level4 || level5 || level6 || level7) && (
              <ListItem button onClick={() => handleListItemClick('NotificationForm')}
              sx={{
                backgroundColor: selectedComponent === 'NotificationForm' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><SendIcon /></ListItemIcon>
                <ListItemText primary="Notifications" />
              </ListItem>
            )}
            {!(level3 || level2 || level4 || level5 || level7) && (
              <ListItem button onClick={() => handleListItemClick('Sponsors')}
              sx={{
                backgroundColor: selectedComponent === 'Sponsors' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><HandshakeIcon /></ListItemIcon>
                <ListItemText primary="Sponsors" />
              </ListItem>
            )}
            {!(level3 || level4 || level5 || level7) && (
              <ListItem button onClick={() => handleListItemClick('Speakers')}
              sx={{
                backgroundColor: selectedComponent === 'Speakers' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><MicExternalOnIcon /></ListItemIcon>
                <ListItemText primary="Speakers" />
              </ListItem>
            )}
            {!(level3 || level4 || level5 || level7) && (
              <ListItem button onClick={() => handleListItemClick('GotTalentManagment')}
              sx={{
                backgroundColor: selectedComponent === 'GotTalentManagment' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><AudiotrackIcon /></ListItemIcon>
                <ListItemText primary="Got-Talent" />
              </ListItem>
            )}
            {!(level3 || level2 || level4 || level5 || level7) && (
              <ListItem button onClick={() => handleListItemClick('News')}
              sx={{
                backgroundColor: selectedComponent === 'News' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><NewspaperIcon /></ListItemIcon>
                <ListItemText primary="News" />
              </ListItem>
            )}
            {!(level3 || level2 || level4 || level5 || level6 || level7) && (
              <ListItem button onClick={() => handleListItemClick('Rating')}
              sx={{
                backgroundColor: selectedComponent === 'Rating' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><StarIcon /></ListItemIcon>
                <ListItemText primary="Rating" />
              </ListItem>
            )}
            {!(level3 || level2 || level4 || level5 || level6 || level7) && (
              <ListItem button onClick={() => handleListItemClick('Generate')}
              sx={{
                backgroundColor: selectedComponent === 'Generate' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><Dateicon /></ListItemIcon>
                <ListItemText primary="Generate Dates" />
              </ListItem>
            )} 
            {!(level3 || level2 || level4 || level5 || level6 || level7) && (
              <ListItem button onClick={() => handleListItemClick('ProblemView')}
              sx={{
                backgroundColor: selectedComponent === 'ProblemView' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><ReportProblemIcon /></ListItemIcon>
                <ListItemText primary="Reported Issues" />
              </ListItem>
            )} 
{!(level3 || level2 || level4 || level5 || level6 || level7) && (
              <ListItem button onClick={() => handleListItemClick('ControlForms')}
              sx={{
                backgroundColor: selectedComponent === 'ControlForms' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><SettingsRemoteIcon /></ListItemIcon>
                <ListItemText primary="Control" />
              </ListItem>
            )} 


            {!(level2 || level4 || level5 || level6 || level7) && (
              <ListItem button onClick={() => handleListItemClick('Hotel')}
              sx={{
                backgroundColor: selectedComponent === 'Hotel' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><HotelIcon /></ListItemIcon>
                <ListItemText primary="Hotel" />
              </ListItem>
            )}
            {!(level3 || level2 || level4 || level5 || level6 || level7) && (
              <ListItem button onClick={() => handleListItemClick('Feedback')}
              sx={{
                backgroundColor: selectedComponent === 'Feedback' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><FeedbackIcon /></ListItemIcon>
                <ListItemText primary="Exhibitors Feedback" />
              </ListItem>
            )}
            {!(level2 || level4 || level5 || level6 || level7) && (
              <ListItem button onClick={() => handleListItemClick('Enquiry')}
              sx={{
                backgroundColor: selectedComponent === 'Enquiry' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><HelpOutlineIcon /></ListItemIcon>
                <ListItemText primary="Enquiry" />
              </ListItem>
            )}
            {!(level2 || level3 || level5 || level6 || level7) && (
              <ListItem button onClick={() => handleListItemClick('Products')}
              sx={{
                backgroundColor: selectedComponent === 'Products' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><ProductionQuantityLimitsIcon /></ListItemIcon>
                <ListItemText primary="Products" />
              </ListItem>
            )}
            {!(level2 || level5 || level6 || level7)  && (
              <ListItem button onClick={() => handleListItemClick('Carts')}
              sx={{
                backgroundColor: selectedComponent === 'Carts' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><ShoppingBasketIcon /></ListItemIcon>
                <ListItemText primary="Carts" />
              </ListItem>
            )}
            {!(level2 || level3 || level4 || level5 || level6 || level7) && (
              <ListItem button onClick={() => handleListItemClick('EmailEditor')}
              sx={{
                backgroundColor: selectedComponent === 'EmailEditor' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><AlternateEmailIcon /></ListItemIcon>
                <ListItemText primary="EmailEditor" />
              </ListItem>
            )}
            {!(level2 || level3 || level4 || level6 || level7) && (
              <ListItem button onClick={() => handleListItemClick('School Management')}
              sx={{
                backgroundColor: selectedComponent === 'School Management' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><SchoolIcon /></ListItemIcon>
                <ListItemText primary="School Management" />
              </ListItem>
            )}
            {!(level2 || level3 || level4 || level5 || level6 || level7) && (
              <ListItem button onClick={() => handleListItemClick('Offers')}
              sx={{
                backgroundColor: selectedComponent === 'Offers' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><LocalOfferIcon /></ListItemIcon>
                <ListItemText primary="Offers" />
              </ListItem>
            )}
          </List>
        </Collapse>
  
        {/* EDUTECH Section */}
        <ListItem button onClick={() => handleExpandClick('EDUTECH')}>
        <Avatar style={{ marginRight: 16 }} alt="EDU GATE Logo" src="./Edutech_logo2.ico" />
          <ListItemText primary="EDU TECH" />
          {expandedSection === 'EDUTECH' ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={expandedSection === 'EDUTECH'} timeout="auto" unmountOnExit>
        {!(level3 || level2 || level4 || level5 || level6 || level7) && (
              <ListItem button onClick={() => handleListItemClick('EdutechView')}
              sx={{
                backgroundColor: selectedComponent === 'EdutechView' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><HowToRegIcon /></ListItemIcon>
                <ListItemText primary="Registrations" />
              </ListItem>
            )}
            {!(level3 || level2 || level4 || level5 || level6 || level7) && (
              <ListItem button onClick={() => handleListItemClick('EdutechViewForum')}
              sx={{
                backgroundColor: selectedComponent === 'EdutechViewForum' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><ForumIcon /></ListItemIcon>
                <ListItemText primary="Forum" />
              </ListItem>
            )} 
            {!(level3 || level2 || level4 || level5 || level6 || level7) && (
              <ListItem button onClick={() => handleListItemClick('AgendaManagementEdutech')}
              sx={{
                backgroundColor: selectedComponent === 'AgendaManagementEdutech' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><ViewAgendaIcon /></ListItemIcon>
                <ListItemText primary="Agenda" />
              </ListItem>
            )} 
            {!(level3 || level2 || level4 || level5 || level6 || level7) && (
              <ListItem button onClick={() => handleListItemClick('EdutechPublicInfo')}
              sx={{
                backgroundColor: selectedComponent === 'EdutechPublicInfo' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><PublicIcon /></ListItemIcon>
                <ListItemText primary="Public Info" />
              </ListItem>
            )} 
            
            {!(level3 || level2 || level4 || level5 || level6 || level7) && (
              <ListItem button onClick={() => handleListItemClick('GenerateEdutech')}
              sx={{
                backgroundColor: selectedComponent === 'GenerateEdutech' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><Dateicon /></ListItemIcon>
                <ListItemText primary="Generate Dates" />
              </ListItem>
            )}
            {!(level3 || level4 || level5 || level7) && (
              <ListItem button onClick={() => handleListItemClick('SpeakersEdutech')}
              sx={{
                backgroundColor: selectedComponent === 'SpeakersEdutech' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><MicExternalOnIcon /></ListItemIcon>
                <ListItemText primary="Speakers" />
              </ListItem>
            )}       
            {!(level3 || level4 || level5 || level7) && (
              <ListItem button onClick={() => handleListItemClick('EDUTECHVideo')}
              sx={{
                backgroundColor: selectedComponent === 'EDUTECHVideo' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><VideoCallIcon /></ListItemIcon>
                <ListItemText primary="Sessions" />
              </ListItem>
            )}         
             {!(level3 || level4 || level5 || level7) && (
              <ListItem button onClick={() => handleListItemClick('SpeakersEdutechForum')}
              sx={{
                backgroundColor: selectedComponent === 'SpeakersEdutechForum' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><GroupsIcon /></ListItemIcon>
                <ListItemText primary="Forum Speakers" />
              </ListItem>
            )} 
            {!(level3 || level4 || level5 || level7) && (
              <ListItem button onClick={() => handleListItemClick('EdutechOfficialPartners')}
              sx={{
                backgroundColor: selectedComponent === 'EdutechOfficialPartners' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><HandshakeIcon /></ListItemIcon>
                <ListItemText primary="Offical Sponsors" />
              </ListItem>
            )} 
            {!(level3 || level4 || level5 || level7) && (
              <ListItem button onClick={() => handleListItemClick('EdutechPartners')}
              sx={{
                backgroundColor: selectedComponent === 'EdutechPartners' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><HandshakeIcon /></ListItemIcon>
                <ListItemText primary="Sponsors" />
              </ListItem>
            )} 
            {!(level2 || level4 || level5 || level6 || level7) && (
              <ListItem button onClick={() => handleListItemClick('EnquiryEdutech')}
              sx={{
                backgroundColor: selectedComponent === 'EnquiryEdutech' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><HelpOutlineIcon /></ListItemIcon>
                <ListItemText primary="Enquiry" />
              </ListItem>
            )}        
             </Collapse> 
        
        <ListItem button onClick={() => handleExpandClick('Agritech')}>
        <Avatar style={{ marginRight: 16 }} alt="EDU GATE Logo" src="./logo-agritech-colored-min.ico" />
          <ListItemText primary="Agritech" />
          {expandedSection === 'Agritech' ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={expandedSection === 'Agritech'} timeout="auto" unmountOnExit>
        {!(level3 || level2 || level4 || level5 || level6 || level7) && (
              <ListItem button onClick={() => handleListItemClick('AgritechView')}
              sx={{
                backgroundColor: selectedComponent === 'AgritechView' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><HowToRegIcon /></ListItemIcon>
                <ListItemText primary="Registrations" />
              </ListItem>
            )}       
           </Collapse>
  
        {/* Miningtech Section  */}
        <ListItem button onClick={() => handleExpandClick('Miningtech')}>
        <Avatar style={{ marginRight: 16 }} alt="EDU GATE Logo" src="./mining_tech.ico" />
          <ListItemText primary="Miningtech" />
          {expandedSection === 'Miningtech' ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={expandedSection === 'Miningtech'} timeout="auto" unmountOnExit>
        {!(level3 || level2 || level4 || level5 || level6 || level7) && (
              <ListItem button onClick={() => handleListItemClick('MiningView')}
              sx={{
                backgroundColor: selectedComponent === 'MiningView' ? 'rgba(255, 99, 71, 0.3)' : 'inherit',
                '&:hover': {
                  backgroundColor: 'rgba(255, 99, 71, 0.4)',
                },
              }}
              >
                <ListItemIcon><HowToRegIcon /></ListItemIcon>
                <ListItemText primary="Registrations" />
              </ListItem>
            )}
        </Collapse>
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;
  const getDefaultComponent = () => {
    if (sessionStorage.getItem('levelnum') === "2") {
      return 'Speakers'; // If level is 2, default component is Speakers
    } else if (sessionStorage.getItem('levelnum') === "3") {
      return 'Hotel'; // If level is 3, default component is Hotel
      
    }else if (sessionStorage.getItem('levelnum') === "4") {
      return 'Carts'; // If level is 3, default component is Hotel
    }  
    else if (sessionStorage.getItem('levelnum') === "5") {
      return 'School Management'; // If level is 3, default component is Hotel
    }  
    else if (sessionStorage.getItem('levelnum') === "6") {
      return 'Sponsors'; // If level is 3, default component is Hotel
    }  
    else if (sessionStorage.getItem('levelnum') === "7") {
      return 'TransferredParticipants'; // If level is 3, default component is Hotel
    }  
    else {
      return 'Overview'; // Default to Home if level is not set
    }
  };
  
  
  const renderSelectedComponent = () => {
    const defaultComponent = getDefaultComponent(); // Use any of the levels
    if(!selectedComponent){
      handleExpandClick('EDUGATE');
        handleListItemClick(defaultComponent);
    }

    switch (selectedComponent || defaultComponent) {
      case 'Overview':
        return <Overview />;
      case 'Events':
        return <Events />;
      case 'Exhibitors':
        return <Exhibitors />;
      case 'Registrations':
        return <OrderManagement />;
      case 'Sponsors':
        return <Sponsors />;
      case 'Speakers':
        return <Stage_check />;
        case 'SpeakersEdutech':
        return <Stage_Check_Edutech />;
        case 'EDUTECHVideo':
          return <EDUTECHVideo />;
      case 'News':
        return <News />;
        case 'NotificationForm':
          return <NotificationForm />;
        
      case 'Rating':
        return <Rating />;
      case 'Generate':
        return <Generate />; 
        case 'GotTalentManagment':
          return <GotTalentManagment />;
      case 'Hotel':
        return <Hotel />;
      case 'Feedback':
        return <Feedback />;
      case 'Enquiry':
        return <EnquireTrack />;
        case 'EnquiryEdutech':
          return <EnquireTrackEdutech />;
        case 'Products':
        return <Products />;
        case 'Carts':
          return <Carts />;
          case 'EmailEditor':
          return <EmailEditor />;
          case 'School Management':
          return <SchoolManagement />;
          case 'Offers':
          return <Offers />;
          case 'MiningView':
          return <MiningView />;
          case 'AgritechView':
          return <AgritechView />;  
          case 'EdutechView':
          return <EdutechView />;  
          case 'EdutechViewForum':
          return <EdutechViewForum />;  
          case 'GenerateEdutech':
            return <GenerateEdutech />;  
            case 'ProblemView':
              return <ProblemView />;  
              case 'ControlForms':
              return <ControlForms />;  
              case 'SpeakersEdutechForum':
                return <SpeakersEdutechForum />;  
                case 'EdutechOfficialPartners':
                  return <EdutechOfficialPartners />;  
                  case 'EdutechPartners':
                    return <EdutechPartners />;  
                    case 'AgendaManagementEdutech':
                      return <AgendaManagementEdutech />;  
                      case 'EdutechPublicInfo':
                      return <EdutechPublicInfo />;  
                      case 'TransferredParticipants':
                      return <TransferredParticipants />;  
                      case 'StudentServices':
                      return <StudentServices />;  
          default: 
            return <Overview />; 
    }
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: { sm: `calc(100% - ${drawerWidth}px)` },
            ml: { sm: `${drawerWidth}px` },
            background: 'linear-gradient(to right, #ff512f, #dd2476)',
            boxShadow: '0 3px 5px 2px rgba(255, 105, 135, 0.3)',
            borderRadius: '10px',
          }}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
              <DashboardIcon /> Dashboard
            </Typography>
            <Typography variant="h6" noWrap component="div">
              Welcome, {name}!
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Tooltip title="Notifications">
  <IconButton
    color="inherit"
    aria-label="notifications"
    sx={{
      mr: 2,
      position: 'relative',
      zIndex: 1200,
      display: { xs: 'none', sm: 'flex' }, // Hide on small screens
    }}
  >
    <NotificationMenu notifications={notifications} username={sessionStorage.getItem('username')} />
  </IconButton>
</Tooltip>

            <Tooltip title="Report a Problem">
              <IconButton
                color="inherit"
                aria-label="report-problem"
                sx={{
                  mr: 2,
                  position: 'relative',
                  zIndex: 1200,
                }}
              >
          <ReportProblem dynamicNumber="Dashboard" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Settings">
              <IconButton
                color="inherit"
                aria-label="settings"
                onClick={handleSettingsOpen}
                edge="end"
                sx={{ mr: 2 }}
              >
                <Avatar
                  alt={name}
                  src={avatar}
                  sx={{
                    width: 40,
                    height: 40,
                    border: '2px solid white',
                    cursor: 'pointer',
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title="Logout">
              <IconButton
                color="inherit"
                aria-label="logout"
                onClick={handleLogout}
                edge="end"
              >
                <LogoutIcon />
              </IconButton>
            </Tooltip>
          </Toolbar>
        </AppBar>
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
          aria-label="mailbox folders"
        >
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onTransitionEnd={handleDrawerTransitionEnd}
            onClose={handleDrawerClose}
            ModalProps={{ keepMounted: true }}
            sx={{
              display: { xs: 'block', sm: 'none' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
          >
            {drawer}
          </Drawer>
          <Drawer
            variant="permanent"
            sx={{
              display: { xs: 'none', sm: 'block' },
              '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            }}
            open
          >
            {drawer}
          </Drawer>
        </Box>
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
        >
          <Toolbar />
          {renderSelectedComponent()}
        </Box>
      </ThemeProvider>
      <SettingsModal
        open={settingsModalOpen}
        handleClose={handleSettingsClose}
        user={{ name, username, avatar, levelnum }}
        updateUser={updateUser}
      />
    </Box>
  );
}  
ResponsiveDrawer.propTypes = {
  window: PropTypes.func,
};

export default ResponsiveDrawer;