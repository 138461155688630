// src/components/AddModal.js
import React from 'react';
import { Modal, Box, Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem, Autocomplete, LinearProgress } from '@mui/material';

const AddModal = ({
  addModalOpen, handleAddClose, handleAddSave, newSchool, handleNewSchoolChange,
  governoratesInEgypt, handleCityInputChange, progress
}) => {
  return (
    <Modal
  open={addModalOpen}
  onClose={handleAddClose}
  aria-labelledby="add-new-school-title"
  aria-describedby="add-new-school-description"
  sx={{ zIndex: 1300 }} // Ensure the modal is above other content
>
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: { xs: '90%', sm: '80%', md: '60%' },
      maxHeight: '90%',
      bgcolor: 'background.paper',
      boxShadow: 24,
      borderRadius: 2,
      p: 4,
      overflowY: 'auto',
      outline: 'none',
    }}
  >
    <Typography
      id="add-new-school-title"
      variant="h6"
      component="h2"
      gutterBottom
      sx={{ fontWeight: 'bold', mb: 3, color: 'primary.main' }}
    >
      Add New School
    </Typography>

    <TextField
      name="name"
      label="School Name"
      variant="outlined"
      fullWidth
      margin="normal"
      value={newSchool.name}
      onChange={handleNewSchoolChange}
      required
      sx={{ mb: 1 }}
    />

    <TextField
      name="representative"
      label="Representative"
      variant="outlined"
      fullWidth
      margin="normal"
      value={newSchool.representative}
      onChange={handleNewSchoolChange}
      required
      sx={{ mb: 1 }}
    />

    <TextField
      name="address"
      label="Address"
      variant="outlined"
      fullWidth
      margin="normal"
      value={newSchool.address}
      onChange={handleNewSchoolChange}
      sx={{ mb: 1 }}
    />

    <TextField
      name="recommendedBy"
      label="Recommended By"
      variant="outlined"
      fullWidth
      margin="normal"
      value={newSchool.recommendedBy}
      onChange={handleNewSchoolChange}
      sx={{ mb: 1 }}
    />

    <TextField
      name="email"
      label="Email"
      variant="outlined"
      fullWidth
      margin="normal"
      value={newSchool.email}
      onChange={handleNewSchoolChange}
      sx={{ mb: 1 }}
    />

<TextField
  name="phone"
  label="Phone"
  variant="outlined"
  fullWidth
  margin="normal"
  value={newSchool.phone}
  onChange={(e) => {
    const value = e.target.value;
    // Allow only numeric values
    if (/^\d+$/.test(value)) {
      handleNewSchoolChange(e); // Call your handler when input is valid
    }
  }}
  required
  sx={{ mb: 1 }}
  type="text" // Using 'text' to have control over empty state validation
  //error={newSchool.phone === ""} // Error if the field is empty
  //helperText={newSchool.phone === "" ? "Phone number is required" : ""} // Error message if empty
/>


    <FormControl fullWidth variant="outlined" sx={{ mb: 1 }}>
      <InputLabel>Education Type</InputLabel>
      <Select
        name="type"
        value={newSchool.type}
        onChange={handleNewSchoolChange}
        required
        label="Education Type"
      >
        <MenuItem value="American Diploma">American Diploma</MenuItem>
        <MenuItem value="IGCSE Diploma">IGCSE Diploma</MenuItem>
        <MenuItem value="Thanaweya Amma">Thanaweya Amma</MenuItem>
        <MenuItem value="Canadian Diploma">Canadian Diploma</MenuItem>
        <MenuItem value="International Baccalaureate Diploma">International Baccalaureate Diploma</MenuItem>
        <MenuItem value="French Baccalaureate Diploma">French Baccalaureate Diploma</MenuItem>
        <MenuItem value="German Abitur">German Abitur</MenuItem>
        <MenuItem value="BTEC">BTEC</MenuItem>
        <MenuItem value="WAEC">WAEC</MenuItem>
        <MenuItem value="Nile Certificarte (Cambridge)">Nile Certificarte (Cambridge)</MenuItem>
        <MenuItem value="International Education System">International Education System</MenuItem>
        <MenuItem value="Foreign Certificate">Foreign Certificate</MenuItem>
        <MenuItem value="Other">Other</MenuItem>
      </Select>
    </FormControl>

    <TextField
      name="administration"
      label="Administration"
      variant="outlined"
      fullWidth
      margin="normal"
      value={newSchool.administration}
      onChange={handleNewSchoolChange}
      required
      sx={{ mb: 1 }}
    />

    <Autocomplete
      id="city-select-demo"
      fullWidth
      options={governoratesInEgypt}
      autoHighlight
      getOptionLabel={(option) => option?.label || ''} // Handle null option
      value={governoratesInEgypt.find((g) => g.label === newSchool.city) || null}
      onChange={(event, value) => handleCityInputChange(event, value, 'select-option')}
      inputValue={newSchool.city || ''} // Ensure inputValue is a string
      onInputChange={(event, value, reason) => handleCityInputChange(event, value, reason)}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Choose a city"
          required
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // Disable autocomplete to avoid autofill issues
          }}
          name="city"
          variant="outlined"
        />
      )}
      sx={{ mb: 1 }}
    />

    <Button
      variant="contained"
      color="primary"
      onClick={handleAddSave}
      fullWidth
      sx={{ mt: 2, py: 1.5 }}
    >
      Save
    </Button>

    {progress && (
      <Box sx={{ width: '100%', mt: 2 }}>
        <LinearProgress />
      </Box>
    )}
  </Box>
</Modal>

  );
};

export default AddModal;
