import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  IconButton
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import CommentIcon from '@mui/icons-material/Comment';
import EditIcon from '@mui/icons-material/Edit';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import WarningIcon from '@mui/icons-material/Warning';

const SchoolTable = ({ filteredSchools, handleEditOpen, handleContactOpen, handleTrophyOpen, availableEvents }) => {
  const [openCommentModal, setOpenCommentModal] = useState(false);
  const [selectedComment, setSelectedComment] = useState('');

  const handleOpenCommentModal = (comment) => {
    setSelectedComment(comment);
    setOpenCommentModal(true);
  };

  const handleCloseCommentModal = () => {
    setOpenCommentModal(false);
    setSelectedComment('');
  };

  const columns = [
    { field: 'name', headerName: 'School Name', width: 180 },
    { field: 'representative', headerName: 'Representative', width: 180 },
    { field: 'recommendedBy', headerName: 'Recommended By', width: 180 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'type', headerName: 'Education Type', width: 180 },
    { field: 'administration', headerName: 'Administration', width: 180 },
    { field: 'city', headerName: 'City', width: 150 },
    {
      field: 'events',
      headerName: 'Event',
      width: 200,
      renderCell: (params) => {
        if (!Array.isArray(params.value) || params.value.length === 0) return 'N/A';
        
        const events = params.value.map(eventObj => {
          const eventDetails = availableEvents.find(avEvent => avEvent.id === eventObj.event_id);
          return eventDetails ? eventDetails.name : null;
        }).filter(name => name); // Filter out null values
  
        return events.length > 0 ? events.join(', ') : 'N/A';
      }
    },
    {
      field: 'whichDay',  // This is just an identifier for the column
      headerName: 'Which Day',
      width: 200,
      renderCell: (params) => {
        if (!Array.isArray(params.row.events) || params.row.events.length === 0) return 'N/A';
        
        const days = params.row.events.map(eventObj => {
          const eventDetails = availableEvents.find(avEvent => avEvent.id === eventObj.event_id);
          return eventDetails ? eventObj.which_day : null;
        }).filter(whichDay => whichDay); // Filter out null values
  
        return days.length > 0 ? days.join(', ') : 'N/A';
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 180,
      renderCell: (params) => {
        switch (params.value) {
          case 'Coming': return <CheckCircleIcon style={{ color: 'green' }} />;
          case 'Not Coming': return <CancelIcon style={{ color: 'red' }} />;
          case 'Maybe Coming': return <HourglassBottomIcon style={{ color: 'orange' }} />;
          default: return <WarningIcon style={{ color: 'red' }} />;
        }
      }
    },
    {
      field: 'comment',
      headerName: 'Comment',
      width: 180,
      renderCell: (params) => (
        <IconButton onClick={() => handleOpenCommentModal(params.value)}>
          <CommentIcon />
        </IconButton>
      )
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 180,
      renderCell: (params) => (
        <Box>
          <IconButton onClick={() => handleContactOpen(params.row)}>
            <ContactPhoneIcon />
          </IconButton>
          <IconButton onClick={() => handleEditOpen(params.row)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleTrophyOpen(params.row)}>
            <EmojiEventsIcon style={{ color: 'gold' }} />
          </IconButton>
        </Box>
      )
    }
  ];
  

  return (
    <Box sx={{ height: 600, width: '100%' }}>
      <DataGrid
        rows={filteredSchools}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10, 25, 50]}
        disableSelectionOnClick
        getRowId={(row) => row._id}
        sx={{
          '& .MuiDataGrid-columnHeaders': {
            position: 'sticky',
            top: 0,
            zIndex: 1,
          },
        }}
      />

      {/* Comment Modal */}
      <Dialog open={openCommentModal} onClose={handleCloseCommentModal} maxWidth="sm" fullWidth>
        <DialogTitle>Comment</DialogTitle>
        <DialogContent>
          <Typography variant="body1">{selectedComment || 'No comment available.'}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCommentModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SchoolTable;
