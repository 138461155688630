import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DataGrid } from '@mui/x-data-grid';
import {
  Box,
  Button,
  Modal,
  TextField,
  LinearProgress,
  IconButton,
  FormControl,
  Typography,
  Alert,
  Collapse,
  Grid,
  Select, MenuItem,InputLabel,InputAdornment
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import CommentIcon from '@mui/icons-material/Comment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import LinkIcon from '@mui/icons-material/Link';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import moment from 'moment'; // For formatting the date and time
import { Autocomplete, Chip } from '@mui/material';
import countries from './countries'; // Import your country list
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PercentIcon from '@mui/icons-material/Percent';
import CalculateIcon from '@mui/icons-material/Calculate';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import DocumentPreviewModal from './DocumentPreviewModal';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
const TransferredParticipants = () => {
  const [selectedBefore, setSelectedBefore] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editOpen, setEditOpen] = useState(false);
  const [currentParticipant, setCurrentParticipant] = useState(null);
  const [statusFilter, setStatusFilter] = useState('');
  const [stageFilter, setStageFilter] = useState('');
  const [userFilter, setUserFilter] = useState('');
  const [photo, setPhoto] = useState(null);
  const [newComment, setNewComment] = useState('');
  const [commentOpen, setCommentOpen] = useState(false);
  const [desiredFields, setDesiredFields] = useState([]);
  const [alertOpen, setAlertOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState('');
  const [comment, setComment] = useState('');
  const [open, setOpen] = useState(false);

  const handleOpen = (participant) => {
    setCurrentParticipant(participant);
    setOpen(true);


  }

  const handleClose = () => {
    setOpen(false);
    setCurrentParticipant(null);

  
  }
  const theme = createTheme({
    palette: {
      primary: {
        main: "#8B0000",
      },
      secondary: {
        main: "#000000",
      },
    },
    typography: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
  });
  // Function to get country flag based on code
const getFlagEmoji = (countryCode) =>
  typeof String.fromCodePoint !== 'undefined'
    ? countryCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : null;

  const fetchTransferredParticipants = async () => {
    const username = sessionStorage.getItem('username'); // Get the username from session storage
    const levelnum = sessionStorage.getItem('levelnum');
    
    try {
      let response;//
      if (levelnum === '1'|| levelnum === '3') {
        // Fetch all transferred participants if levelnum is '1'
        response = await axios.get(`https://edutech-backend-471f7cb69116.herokuapp.com/api/alltransferred-participants`);
      } else {
        // Otherwise, fetch transferred participants specific to the username
        response = await axios.get(`https://edutech-backend-471f7cb69116.herokuapp.com/api/transferred-participants`, {
          params: { username },
        });
      }
  
      // Ensure response data exists before reversing and setting state
      if (response?.data) {
        setSelectedBefore(response.data.reverse()); // Update state with fetched participants
      } else {
      }
    } catch (error) {
    } finally {
      setLoading(false); // End loading state after fetch attempt
    }
  };
  

  useEffect(() => {
    fetchTransferredParticipants();
  }, []);


  useEffect(() => {
    // Fetch figures data from the API
    axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/figures')
      .then(response => {
        setDesiredFields(response.data[3].desiredFields);
        response="";
      })
      .catch(error => console.error('Error fetching figures data:', error));
  }, []);


  const handleCommentOpen = (participant) => {
    setCurrentParticipant(participant);
    setCommentOpen(true);
  };

  const handleCommentClose = () => {
    setCurrentParticipant(null);
    setCommentOpen(false);
    setNewComment('');
  };
  const handleEditOpen = (participant) => {
    setCurrentParticipant(participant);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setCurrentParticipant(null);
    setEditOpen(false);
    setPhoto(null)
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
        const { _id,username, ...participantData } = currentParticipant; // Include username if present

        participantData.photo = photo; // Add the photo field to the new object
        await axios.put(`https://edutech-backend-471f7cb69116.herokuapp.com/api/update-participant/${_id}`,{
        username, // Ensure username is included
            ...participantData,
        },
        {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
    );

       
        setMessage('Participant updated successfully');
        setSeverity('success');
        setAlertOpen(true);
        fetchTransferredParticipants(); // Refresh the data
    } catch (error) {
        setMessage('Error updating participant: ' + error.message);
        setSeverity('error');
        setAlertOpen(true);
    }
    handleEditClose();
};

const handlePhotoUpload = (event) => {
  const file = event.target.files[0];
  if (file) {
    const validFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];
    if (!validFileTypes.includes(file.type)) {
      setMessage("Please upload a valid file (JPEG, PNG, or PDF)");
      setSeverity("error");
      setAlertOpen(true);
      setPhoto(null);
      return;
    }

    const maxSizeBytes = 2 * 1024 * 1024; // 2MB in bytes
    if (file.size > maxSizeBytes) {
      setMessage("File size exceeds the limit of 2MB. Please choose a smaller file.");
      setSeverity("error");
      setAlertOpen(true);
      setPhoto(null);
      return;
    }

    // Check if it's a PDF
    if (file.type === 'application/pdf') {
      const extension = file.name.split('.').pop();
      const newName = `edugate_student_document_${Date.now()}.${extension}`;
      const renamedFile = new File([file], newName, { type: file.type });
      setPhoto(renamedFile);
    } else {
      // If it's an image, perform image-specific validation
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          // File is a valid image
          const extension = file.name.split('.').pop();
          const newName = `edugate_contract_${Date.now()}.${extension}`;
          const renamedFile = new File([file], newName, { type: file.type });
          setPhoto(renamedFile);
        };
        img.onerror = () => {
          setMessage("The uploaded file is not a valid image or is corrupted");
          setSeverity("error");
          setAlertOpen(true);
          setPhoto(null);
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  }
};

const finalAmount = currentParticipant && currentParticipant.tuitionFees != null && currentParticipant.commissionPercent != null
    ? ((currentParticipant.tuitionFees * (currentParticipant.commissionPercent / 100)).toFixed(2))
    : '0.00';


  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const handleStageFilterChange = (event) => {
    setStageFilter(event.target.value);
  };

  

  const handleUserFilterChange = (event) => {
    setUserFilter(event.target.value);
  };
  const filteredParticipants = selectedBefore.filter((participant) => {
    // Check both statusFilter and userFilter
    const statusMatches = !statusFilter || participant.status === statusFilter;
    const userMatches = !userFilter || participant.username.includes(userFilter);
    const stageMatches = !stageFilter || participant.stage.includes(stageFilter);

    return statusMatches && userMatches &&stageMatches ;
  });
  

  const columns = [
    { field: 'f_name', headerName: 'First Name', width: 150 },
    { field: 'l_name', headerName: 'Last Name', width: 150 },
   { field: 'email', headerName: 'Email', width: 200 },
    { field: 'mobile', headerName: 'Mobile', width: 150 },
    { field: 'country', headerName: 'Nationality', width: 150 },
    { field: 'birthdate', headerName: 'Birthdate', width: 150 },
    { field: 'education_type', headerName: 'Education Type', width: 150 },
    { field: 'hear_aboutus_at', headerName: 'Hear about us at', width: 150 },
    { field: 'dateTime', headerName: 'Date', width: 150 },
    
    {
      field: 'files',
      headerName: 'Files',
      width: 100,
      renderCell: (params) => (
        <IconButton onClick={() => handleOpen(params.row)}>
          <FolderOpenIcon /> 
        </IconButton>
      ),
    },
    {
      field: 'comment',
      headerName: 'Comments',
      width: 100,
      renderCell: (params) => (
        <IconButton onClick={() => handleCommentOpen(params.row)}>
          <CommentIcon />
        </IconButton>
      ),
    },
    {
      field: 'link',
      headerName: 'Document',
      width: 100,
      renderCell: (params) => {
        const comments = params.value;
    
        // Check if the field is not empty
        if (comments && comments.trim() !== "") {
          return (
            <IconButton href={params.value} target="_blank" rel="noopener noreferrer">
          <LinkIcon />
        </IconButton>
          );
        }
    
        return null;
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150,
      renderCell: (params) => {
        let icon;
        let color;
    
        switch (params.value) {
          case 'Hot':
            icon = <WhatshotIcon style={{ color: 'orange' }} />;
            color = 'green'; // Or any color you'd like for hot status
            break;
          case 'Cold':
            icon = <AcUnitIcon style={{ color: 'blue' }} />;
            color = 'blue'; // Change to your desired color for cold
            break;
          case 'Warm':
            icon = <HourglassBottomIcon style={{ color: 'gold' }} />;
            color = 'orange'; // Change to your desired color for warm
            break;
          default:
            icon = null; // No icon for unknown status
            color = 'gray'; // Default color
            break;
        }
    
        return (
          <span style={{ color }}>
            {icon} {params.value}
          </span>
        );
      },
    },
    ,
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <IconButton onClick={() => handleEditOpen(params.row)}>
          <EditIcon />
        </IconButton>
      ),
    },
  ];
  const handleCommentSubmit = async () => {
    if (!newComment.trim()) return;  // Ensure comment is not empty

    // Create a new comment object with timestamp
    const commentWithTimestamp = {
        text: newComment,
        dateTime: moment().format('MMMM Do YYYY, h:mm:ss a'), // Current timestamp
    };

    // Append the new comment to the participant's existing comments
    const updatedComments = [...(currentParticipant.comment || []), commentWithTimestamp];

    // Create a new object with the updated comments
    const updatedParticipantData = {
        ...currentParticipant,
        comment: updatedComments,
    };

    // Update the current participant's comments in state
    setCurrentParticipant(updatedParticipantData);
    setNewComment('');

    const { _id, ...participantData } = updatedParticipantData; // Include username if present


    try {
        // Send the entire participant's data without the _id to the backend
        await axios.put(`https://edutech-backend-471f7cb69116.herokuapp.com/api/update-participant/${_id}`, {
            ...participantData,
            photo:null
                    },{
                      headers: {
                        'Content-Type': 'multipart/form-data',
                      },
                    });

        // Optionally, you can show a success message or reload the data from the backend
        fetchTransferredParticipants(); // Refresh the data
        setMessage('Participant updated successfully');
        setSeverity('success');
        setAlertOpen(true);
      } catch (error) {
        setMessage('Error updating participant: ' + error.message);
        setSeverity('error');
        setAlertOpen(true);    }

    // Close the modal after submitting the comment
  };
  const handleDestinationsChange = (event, value) => {
    setCurrentParticipant((prev) => ({
      ...prev,
      preferredDestinations: value, // Update preferred destinations with the new selected values
    }));
  };



  return (
<ThemeProvider theme={theme}>
      <div>     
         <Collapse in={alertOpen}>
        <Alert
          severity={severity}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setAlertOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
          {message}
        </Alert>
      </Collapse>

      <Box sx={{ padding: 2, backgroundColor: '#fff', borderRadius: 2, boxShadow: 3, mb: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>

      <FormControl variant="outlined" fullWidth>
              <InputLabel>Filter by Status</InputLabel>
              <Select
                label="Filter by Status"
                value={statusFilter}
                onChange={handleStatusFilterChange}
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="Cold">Cold</MenuItem>
                      <MenuItem value="Warm">Warm</MenuItem>
                      <MenuItem value="Hot">Hot</MenuItem>
              </Select>
            </FormControl>
      </Grid>
      <Grid item xs={12} sm={6} md={3}>

      <FormControl variant="outlined" fullWidth>
              <InputLabel>Filter by Stage</InputLabel>
              <Select
                label="Filter by Stage"
                value={stageFilter}
                onChange={handleStageFilterChange}
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="Open">Open</MenuItem>
              <MenuItem value="Not Qualified">Not Qualified</MenuItem>
              <MenuItem value="Qualified">Qualified</MenuItem>                 
              <MenuItem value="Finalized">Finalized</MenuItem>
              <MenuItem value="Applied">Applied</MenuItem>
              <MenuItem value="Invoice">Invoice</MenuItem>
              </Select>
            </FormControl>
      </Grid>

      
      {(sessionStorage.getItem('levelnum') === '1' || sessionStorage.getItem('levelnum') === '3') && (
  <Grid item xs={12} sm={6} md={3}>
    <FormControl variant="outlined" fullWidth>
      <InputLabel>Filter by User</InputLabel>
      <Select
        label="Filter by User"
        value={userFilter}
        onChange={handleUserFilterChange}
      >
        <MenuItem value="">All</MenuItem>
        <MenuItem value="studyabroad_hussein">Mohamed Hussein</MenuItem>
        <MenuItem value="studyabroad@soha">Soha Sherif</MenuItem>
      </Select>
    </FormControl>
  </Grid>
)}
        </Grid>
      </Box>
      

      {loading && (
        <Box sx={{ width: '100%' }}>
          <LinearProgress />
        </Box>
      )}
      {loading ? (
        <p>Loading...</p>
      ) : (
        <DataGrid
          rows={filteredParticipants}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10, 20, 50]}
          checkboxSelection
          autoHeight
        />
      )}

      <Modal open={editOpen} onClose={handleEditClose}
              sx={{ zIndex: 1300 }} // Ensure the modal is above other content
      >
        <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            maxWidth: 600,
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            overflowY: 'auto',
            maxHeight: '85%',
          }}>
          <Typography
      id="modal-title"
      variant="h5"
      component="h2"
      sx={{ mb: 3, fontWeight: 'bold', color: 'primary.main' }}
    >Edit Participant</Typography>
          <form onSubmit={handleEditSubmit}>

          <FormControl fullWidth margin="normal" variant="outlined">
            <TextField
              label="Mobile"
              value={currentParticipant?.mobile || ''}

              onChange={(e) => setCurrentParticipant({ ...currentParticipant, mobile: e.target.value })}
              fullWidth
              required
            />
          </FormControl>
          <FormControl fullWidth margin="normal" variant="outlined">
            <TextField
              label="Email"
              value={currentParticipant?.email || ''}
              onChange={(e) => setCurrentParticipant({ ...currentParticipant, email: e.target.value })}
              fullWidth
              required
              type='email'
            />
          </FormControl>
          <FormControl fullWidth margin="normal" variant="outlined">
            <TextField
              label="Budget"
              value={currentParticipant?.budget || ''}
              onChange={(e) => setCurrentParticipant({ ...currentParticipant, budget: e.target.value })}
              fullWidth
              type='number'
            />
          </FormControl>
          <FormControl variant="outlined" margin="normal" fullWidth>
              <InputLabel>Highest Education Level</InputLabel>
              <Select
                label="Highest Education Level"
                value={currentParticipant?.highestEducationLevel || ''}
                onChange={(e) => setCurrentParticipant({ ...currentParticipant, highestEducationLevel: e.target.value })}
                >
                <MenuItem value="High School">High School</MenuItem>
                      <MenuItem value="Bachelor's Degree">Bachelor's Degree</MenuItem>
                      <MenuItem value="Master's Degree">Master's Degree</MenuItem>
                      <MenuItem value="Doctorate Degree">Doctorate Degree</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="outlined" margin="normal" fullWidth>
              <InputLabel>Preferred Study Level</InputLabel>
              <Select
                label="Preferred Study Level"
                value={currentParticipant?.preferredStudyLevel || ''}
                onChange={(e) => setCurrentParticipant({ ...currentParticipant, preferredStudyLevel: e.target.value })}
                >
                <MenuItem value="School">School</MenuItem>
                      <MenuItem value="undergraduate">Undergraduate</MenuItem>
                      <MenuItem value="postgraduate">Postgraduate</MenuItem>
                      <MenuItem value="Doctorate">Doctorate</MenuItem>
                      <MenuItem value="English Language">English Language</MenuItem>
                      <MenuItem value="Vocational">Vocational</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>
            <FormControl variant="outlined" margin="normal" fullWidth>
              <InputLabel>English Certificate</InputLabel>
              <Select
                label="English Certificate"
                value={currentParticipant?.englishLevel || ''}
                onChange={(e) => setCurrentParticipant({ ...currentParticipant, englishLevel: e.target.value })}
                >
                <MenuItem value="Oxford">Oxford</MenuItem>
                      <MenuItem value="IELTS">IELTS</MenuItem>
                      <MenuItem value="Edexcel">Edexcel</MenuItem>
                      <MenuItem value="Cambridge">Cambridge</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>
            <Autocomplete
  multiple // Allows multiple selections
  id="desired-field-select"
  fullWidth
  options={desiredFields} // The list of desired fields
  getOptionLabel={(option) => option.label} // Display field label
  value={currentParticipant?.studyFields || []} // Bind selected fields from currentParticipant
  onChange={(event, newValue) => {
    setCurrentParticipant({
      ...currentParticipant,
      studyFields: newValue, // Update currentParticipant with the selected fields
    });
  }}
  renderOption={(props, option) => (
    <li {...props}>
      {option.label}
    </li>
  )}
  renderInput={(params) => (
    <TextField
      {...params}
      label="Desired Fields"
      placeholder="Select desired fields"
      margin="normal"
    />
  )}
  // Display selected values as chips
  renderTags={(value, getTagProps) =>
    value.map((option, index) => (
      <Chip
        label={option.label}
        {...getTagProps({ index })}
      />
    ))
  }
  isOptionEqualToValue={(option, value) => option.label === value.label} // Match by label
/>

          <FormControl variant="outlined" margin="normal" fullWidth>
              <InputLabel>Financial Source</InputLabel>
              <Select
                label="Financial Source"
                value={currentParticipant?.financialSource || ''}
                onChange={(e) => setCurrentParticipant({ ...currentParticipant, financialSource: e.target.value })}
                >
                <MenuItem value="Self-Funded">Self-Funded</MenuItem>
                      <MenuItem value="Parents">Parents</MenuItem>
                      <MenuItem value="Scholarship">Scholarship</MenuItem>
                      <MenuItem value="Bank Loan">Bank Loan</MenuItem>
                      <MenuItem value="Employer Scholarship">Employer Scholarship</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>
            <Autocomplete
              multiple
              id="country-select-demo"
              options={countries}
              getOptionLabel={(option) => option.label} // Show country name as label
              value={currentParticipant?.preferredDestinations || []} // Bind selected countries from currentParticipant
              onChange={handleDestinationsChange} // Handle change to update currentParticipant's destinations
              renderOption={(props, option) => (
                <li {...props}>
                  <span>{getFlagEmoji(option.code)}</span>&nbsp;{option.label}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Preferred Destinations"
                  placeholder="Preferred Destinations"
                  margin="normal"
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    label={option.label}
                    {...getTagProps({ index })}
                    avatar={<span>{getFlagEmoji(option.code)}</span>} // Show flag in chip
                  />
                ))
              }
              isOptionEqualToValue={(option, value) => option.label === value.label} // Ensure values are matched by name
            />
            
           
            <FormControl variant="outlined" margin="normal" fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                label="Status"
                value={currentParticipant?.status || ''}
                onChange={(e) => setCurrentParticipant({ ...currentParticipant, status: e.target.value })}
                >
                <MenuItem value="Cold">Cold</MenuItem>
                      <MenuItem value="Warm">Warm</MenuItem>
                      <MenuItem value="Hot">Hot</MenuItem>

              </Select>
            </FormControl>
            <FormControl variant="outlined" margin="normal" fullWidth>
              <InputLabel>Stage</InputLabel>
              <Select
                label="Stage"
                value={currentParticipant?.stage || ''}
                onChange={(e) => setCurrentParticipant({ ...currentParticipant, stage: e.target.value })}
                >
              <MenuItem value="Open">Open</MenuItem>
              <MenuItem value="Not Qualified">Not Qualified</MenuItem>
              <MenuItem value="Qualified">Qualified</MenuItem>                 
              <MenuItem value="Finalized">Finalized</MenuItem>
              <MenuItem value="Applied">Applied</MenuItem>
              <MenuItem value="Invoice">Invoice</MenuItem>
              </Select>
            </FormControl>
            {currentParticipant?.stage === 'Not Qualified'  && (
              <FormControl variant="outlined"  required margin="normal" fullWidth>
              <InputLabel>Reason</InputLabel>
              <Select
                label="Reason"
                value={currentParticipant?.notQualifiedReason || ''}
                onChange={(e) => setCurrentParticipant({ ...currentParticipant, notQualifiedReason: e.target.value })}
                >
              <MenuItem value="Not Serious">Not Serious</MenuItem>
              <MenuItem value="Not Interested">Not Interested</MenuItem>
              <MenuItem value="Academic Requirments Not Met">Academic Requirments Not Met</MenuItem>                 
              <MenuItem value="Financial Reasons">Financial Reasons</MenuItem>
              <MenuItem value="Wrong Number">Wrong Number</MenuItem>
              <MenuItem value="Wrong Person">Wrong Person</MenuItem>
              <MenuItem value="Signed with other agent">Signed with other agent</MenuItem>
              <MenuItem value="Not Supported Destination">Not Supported Destination</MenuItem>

              </Select>
            </FormControl>
            )}
             {currentParticipant?.stage === 'Applied'  && (
              <FormControl variant="outlined"  required margin="normal" fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                label="Status"
                value={currentParticipant?.appliedStatus || ''}
                onChange={(e) => setCurrentParticipant({ ...currentParticipant, appliedStatus: e.target.value })}
                >
              <MenuItem value="Open">Open</MenuItem>
              <MenuItem value="In Progress">In Progress</MenuItem>
              <MenuItem value="Accepted Offer">Accepted Offer</MenuItem>                 
              <MenuItem value="Rejected Offer">Rejected Offer</MenuItem>
              </Select>
            </FormControl>
            )}
            {currentParticipant?.stage === 'Finalized'  && (
              <FormControl variant="outlined" required margin="normal" fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                label="Status"
                value={currentParticipant?.finalizedStatus || ''}
                onChange={(e) => setCurrentParticipant({ ...currentParticipant, finalizedStatus: e.target.value })}
                >
              <MenuItem value="Conditional Offer">Conditional Offer</MenuItem>
              <MenuItem value="Unconditional Offer">Unconditional Offer</MenuItem>
              <MenuItem value="Visa issuing">Visa issuing</MenuItem>
              </Select>
            </FormControl>
            )}
            {currentParticipant?.stage === 'Invoice'  && (
              <div>
              <FormControl variant="outlined" required margin="normal" fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                label="Status"
                value={currentParticipant?.invoiceStatus || ''}
                onChange={(e) => setCurrentParticipant({ ...currentParticipant, invoiceStatus: e.target.value })}
                >
              <MenuItem value="In Progress">In Progress</MenuItem>
              <MenuItem value="Completed">Completed</MenuItem>
              <MenuItem value="On Hold">On Hold</MenuItem>
              </Select>
            </FormControl>
                          <FormControl variant="outlined" required margin="normal" fullWidth>
            <TextField
            label="Tuition Fees"
            variant="outlined"
            fullWidth
            value={currentParticipant?.tuitionFees || ''}
            onChange={(e) => setCurrentParticipant({ ...currentParticipant, tuitionFees: e.target.value })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AttachMoneyIcon />
                </InputAdornment>
              ),
              inputMode: 'numeric',
            }}
            placeholder="Enter tuition fees"
            margin="normal"
          />
                      </FormControl>
                      <FormControl variant="outlined" required margin="normal" fullWidth>
                      <TextField
              label="Commission (%)"
              variant="outlined"
              fullWidth
              value={currentParticipant?.commissionPercent || ''}
              onChange={(e) => setCurrentParticipant({ ...currentParticipant, commissionPercent: e.target.value })}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PercentIcon />
                  </InputAdornment>
                ),
                inputMode: 'numeric',
              }}
              placeholder="Enter commission %"
              margin="normal"
            />
            </FormControl>
            <Box
              sx={{
                backgroundColor: '#e3f2fd',
                padding: 2,
                borderRadius: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                Final Amount (USD):
              </Typography>
              <Typography variant="h6" color="primary" sx={{ display: 'flex', alignItems: 'center' }}>
                <CalculateIcon sx={{ marginRight: 1 }} />
                ${finalAmount}
              </Typography>
            </Box>

                      </div>

            )}
            <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item xs={12} sm={12}>
              <input
                accept="image/*,application/pdf" // Accepts images and PDFs
                style={{ display: 'none' }}
                id="upload-photo"
                type="file"
                onChange={handlePhotoUpload}
              />
              <label htmlFor="upload-photo">
                <Button
                  startIcon={<CloudUploadIcon />}
                  variant="outlined"
                  component="span"
                  fullWidth
                  sx={{
                    mt: 2,
                    py: 1.5,
                    fontWeight: 'bold',
                    borderColor: 'primary.main',
                    color: 'primary.main',
                  }}
                >
                  Upload Extra Document
                </Button>
              </label>
              {photo && <Typography sx={{ mt: 1, color: 'text.secondary' }}>{photo.name}</Typography>}
            </Grid>
            <Grid item xs={12}>
        <Typography
          variant="body2"
          sx={{
            mt: 2,
            color: 'text.secondary',
            fontWeight: 'medium',
            lineHeight: 1.5,
            textAlign: 'center',
          }}
        >
          📄 <strong>Important:</strong> Please note that you can only upload <strong>one document</strong>. 
          If you have multiple files, kindly <strong>merge them</strong> into a single PDF before uploading. 
          If you've previously uploaded an extra document, please <strong>download the file, merge them all </strong> 
           and re-upload merged file here.
        </Typography>
      </Grid>
          </Grid>
            <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
              Save Changes
            </Button>
          </form>
        </Box>
      </Modal>
      <Modal open={commentOpen} onClose={handleCommentClose}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%',
          maxWidth: 600,
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          overflowY: 'auto',
          maxHeight: '85%',
        }}>
          <Typography variant="h5" component="h2" sx={{ mb: 3, fontWeight: 'bold', color: 'primary.main' }}>
            Comments
          </Typography>

          {/* Display existing comments */}
          {currentParticipant?.comment?.length > 0 ? (
            currentParticipant.comment
              .sort((a, b) => new Date(b.dateTime) - new Date(a.dateTime)) // Order comments by date (most recent first)
              .map((comment, index) => (
                <Box key={index} sx={{ mb: 2, p: 2, border: '1px solid #ddd', borderRadius: 1 }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    {comment.text}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {comment.dateTime}
                  </Typography>
                </Box>
              ))
          ) : (
            <Typography variant="body1" sx={{ color: 'text.secondary' }}>
              No comments yet.
            </Typography>
          )}

          {/* Input field for adding new comment */}
          <TextField
            label="Add a Comment"
            value={newComment}
            multiline
            rows={4}
            onChange={(e) => setNewComment(e.target.value)}
            fullWidth
            required
            margin="normal"
          />

          <Button variant="contained" color="primary" onClick={handleCommentSubmit}>
            Add Comment
          </Button>
        </Box>
      </Modal>
      {currentParticipant && (
        <DocumentPreviewModal
          open={open}
          onClose={handleClose}
          currentParticipant={currentParticipant}
        />
      )}
    </div>
    </ThemeProvider>

  );
};

export default TransferredParticipants;
