import React, { useEffect, useState } from 'react';
import { Typography, Box, Card, CardContent, FormControlLabel, Switch, Button, Paper, CircularProgress, TextField } from '@mui/material';
import axios from 'axios';

const ManageForms = () => {
  const [formStates, setFormStates] = useState(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [maxExportValues, setMaxExportValues] = useState({}); // Store max_export values for users

  useEffect(() => {
    fetchFormStates();
    fetchUsers();  // Fetch users on component mount
  }, []);

  const fetchFormStates = async () => {
    try {
      const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/formstates');
      setFormStates(response.data);
    } catch (error) {
      console.error('Error fetching form states', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get('https://edutech-backend-471f7cb69116.herokuapp.com/api/get2users');
      setUsers(response.data);  // Set the users in state
      // Initialize maxExportValues with fetched user data
      const initialMaxExportValues = response.data.reduce((acc, user) => {
        acc[user.username] = user.max_export; // Set default max_export values
        return acc;
      }, {});
      setMaxExportValues(initialMaxExportValues);
    } catch (error) {
      console.error('Error fetching users', error);
    }
  };

  const handleSwitchChange = (section, form) => (event) => {
    const updatedState = {
      ...formStates,
      [section]: {
        ...formStates[section],
        [form]: event.target.checked,
      },
    };
    setFormStates(updatedState);
  };

  const handleMaxExportChange = (username) => (event) => {
    const newMaxExport = event.target.value;
    setMaxExportValues({
      ...maxExportValues,
      [username]: newMaxExport,
    });
  };

  const handleSave = async () => {
    setSaving(true);
    try {
      // Save form states
      await axios.post('https://edutech-backend-471f7cb69116.herokuapp.com/api/updateformstates', formStates);
  
      // Prepare data for user max_export update
      const usersToUpdate = users.map(user => ({
        username: user.username,
        max_export: maxExportValues[user.username] || user.max_export, // Use the current value or fallback to default if not set
      }));
  
      // Save max_export values for all users
      await axios.post('https://edutech-backend-471f7cb69116.herokuapp.com/api/updateUsersMaxExport', { users: usersToUpdate });
  
      setSaving(false);
      alert('Form states and user exports updated successfully!');
    } catch (error) {
      console.error('Error saving data', error);
      setSaving(false);
    }
  };
  

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Manage Forms
      </Typography>

      {/* Edugate Section */}
      <Paper elevation={3} sx={{ padding: 3, marginBottom: 3 }}>
        <Typography variant="h5" gutterBottom>
          Edugate
        </Typography>
        {renderFormSwitches('Edugate', formStates?.Edugate, handleSwitchChange)}
      </Paper>

      {/* EDU TECH Section */}
      <Paper elevation={3} sx={{ padding: 3, marginBottom: 3 }}>
        <Typography variant="h5" gutterBottom>
          EDU TECH
        </Typography>
        {renderFormSwitches('EDU_TECH', formStates?.EDU_TECH, handleSwitchChange)}
      </Paper>

      {/* Mining TECH Section */}
      <Paper elevation={3} sx={{ padding: 3, marginBottom: 3 }}>
        <Typography variant="h5" gutterBottom>
          Mining TECH
        </Typography>
        {renderFormSwitches('Mining_TECH', formStates?.Mining_TECH, handleSwitchChange)}
      </Paper>

      {/* Agri TECH Section */}
      <Paper elevation={3} sx={{ padding: 3, marginBottom: 3 }}>
        <Typography variant="h5" gutterBottom>
          Agri TECH
        </Typography>
        {renderFormSwitches('Agri_TECH', formStates?.Agri_TECH, handleSwitchChange)}
      </Paper>

      {/* User Data Section */}
      <Paper elevation={3} sx={{ padding: 3, marginBottom: 3 }}>
        <Typography variant="h5" gutterBottom>
          Users
        </Typography>
        {users.map(user => (
          <Card key={user.username} sx={{ marginBottom: 2 }}>
            <CardContent>
              <Typography variant="body1">{user.username}</Typography>
              <TextField
                label="Max Export"
                type="number"
                value={maxExportValues[user.username] || ''}
                onChange={handleMaxExportChange(user.username)}
                variant="outlined"
                fullWidth
                margin="normal"
              />
            </CardContent>
          </Card>
        ))}
      </Paper>

      {/* Save Button */}
      <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 4 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSave}
          disabled={saving}
        >
          {saving ? 'Saving...' : 'Save Changes'}
        </Button>
      </Box>
    </Box>
  );
};

// Helper function to render form switches for each section
const renderFormSwitches = (section, formState, handleSwitchChange) => {
  if (!formState) return null;

  return (
    <Box>
      {Object.keys(formState).map((form) => (
        <Card key={form} sx={{ marginBottom: 2 }}>
          <CardContent>
            <FormControlLabel
              control={
                <Switch
                  checked={!formState[form]} // Reverse the state for switch
                  onChange={handleSwitchChange(section, form)}
                  name={form}
                  color="primary"
                />
              }
              label={form}
            />
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default ManageForms;
