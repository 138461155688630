const countries = [
  { code: 'AU', label: 'Australia', phone: '61', suggested: true },//
  { code: 'CA', label: 'Canada', phone: '1', suggested: true },//
  { code: 'DE', label: 'Germany', phone: '49', suggested: true },//
  { code: 'FR', label: 'France', phone: '33', suggested: true },//
  { code: 'GB', label: 'United Kingdom', phone: '44' },///
  { code: 'HU', label: 'Hungary', phone: '36' },//
  { code: 'ID', label: 'Indonesia', phone: '62' },
  { code: 'IE', label: 'Ireland', phone: '353' },//
  { code: 'MY', label: 'Malaysia', phone: '60' },//
  { code: 'PL', label: 'Poland', phone: '48' },//
  { code: 'RS', label: 'Serbia', phone: '381' },//
  { code: 'RU', label: 'Russia', phone: '7' },//
  { code: 'US', label: 'United States', phone: '1', suggested: true },// , turkey
  { code: 'NZ', label: 'New Zealand', phone: '64' },
  { code: 'TR', label: 'Turkey', phone: '90' },
]
export default countries;
